export const usersConstants={
  UReq:'U_REQ',
  UErr:'U_ERR',
  USuc:'U_SUC',
  UClr:'U_CLR',
  UAddAtt:'U_ADD_ATT',
  UUptAtt:'U_UPT_ATT',
  UDelAtt:'U_DEL_ATT'
}

export const t_authConstants={
  TAReq:'TA_REQ',
  TAErr:'TA_ERR',
  TASuc:'TA_SUC',
  TFASuc:'T_FA_SUC',
  TAClr:'TA_CLR',
  TAAddAtt:'TA_ADD_ATT',
  TFAddAtt:'TF_Add_Att',
  TAUptAtt:'TA_UPT_ATT',
  TADelAtt:'TA_DEL_ATT'
  
}
export const tusers_authConstants={
  TUReq:'TU_REQ',
  TUErr:'TU_ERR',
  TUSuc:'TU_SUC',
  TUClr:'TU_CLR',
  TUAddAtt:'TU_Add_Att',
  TUUptAtt:'TU_UPT_ATT',
  TUDelAtt:'TU_DEL_ATT'
  
}

export const twitch_settings = {
  TSReq: 'TS_REQ',
  TSErr: 'TS_ERR',
  TSSuc: 'TS_SUC',


}

export const notification_settings = {
  NSReq: 'NS_REQ',
  NSErr: 'NS_ERR',
  NSSuc: 'NS_SUC',


}
