import { configureStore } from '@reduxjs/toolkit'

import { UsersReducer } from './reducers/usersReducer';

import { TAReducer, TUserReducer } from './reducers/twitchReducer';
import { NSettingsReducer, TSettingsReducer } from './reducers/settingsReducer';

export const store = configureStore({
  reducer: {
    user: UsersReducer, twitch: TAReducer, tUsers: TUserReducer, TToken: TSettingsReducer, NSetting: NSettingsReducer

  },
})
